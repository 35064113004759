<template>
  <div class="body">
    <div class="orderItem">
      <span class="orderItem-label">就诊人：</span>{{ name }}
    </div>
    <div class="orderItem">
      <span class="orderItem-label">支付单号：</span>{{ orderid }}
    </div>
    <div class="orderItem">
      <span class="orderItem-label">检查项目：</span>{{ formData.lableName }}
    </div>
    <div class="orderItem">
      <span class="orderItem-label">支付时间：</span>{{ formatDate() }}
    </div>
    <div class="orderItem">
      <span class="orderItem-label">应付金额：</span
      ><span style="color: #e27a66"
        >￥{{ (parseInt(formData.amount) / 100).toFixed(2) }}</span
      >
    </div>
    <van-cell-group style="margin: 20px 0">
      <van-cell center>
        <template #title>
          <img
            src="@/assets/img/appwx_logo.png"
            alt=""
            class="appwx_logo"
          /><span class="appwx_text">微信支付</span>
        </template>
        <template #default>
          <van-radio-group v-model="checked">
            <van-radio :name="0" checked-color="#32D8C0"></van-radio>
          </van-radio-group>
        </template>
      </van-cell>
    </van-cell-group>
    <div class="confirm-tips">请确认以上信息是否正确，再选择是否支付</div>
    <div>
      <van-button class="pay-btn" @click="handleConfirm" color="#31AA05"
        >确认支付</van-button
      >
    </div>
    <!-- <div>
        <van-button class="pay-btn" color="#6B6B6B" @click="toClose"
          >关闭当前订单<br>
          <span style="font-size:0.3rem">(关闭后订单被删除,如需要则重新发起订单)</span></van-button
        >
      </div> -->
    <div>
      <van-button class="pay-btn" color="#8A8A8A" @click="toCancle"
        >取消支付</van-button
      >
    </div>
    <van-dialog
      class="dialog"
      v-model="show"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h3 class="dialog-title">提示</h3>
      </template>
      <div>请确认信息是否正确</div>
      <van-row>
        <van-col span="8" style="text-align: right; font-size: 0.4rem"
          >就诊人：</van-col
        >
        <van-col span="16" style="font-size: 0.4rem"
          ><b>{{ name }}</b></van-col
        >
      </van-row>
      <van-row>
        <van-col span="8" style="text-align: right; font-size: 0.4rem"
          >检查项目：</van-col
        >
        <van-col span="16" style="font-size: 0.4rem"
          ><b>{{ formData.lableName }}</b></van-col
        >
      </van-row>
      <van-row>
        <van-col span="8" style="text-align: right; font-size: 0.4rem"
          >应付金额：</van-col
        >
        <van-col span="16" style="font-size: 0.4rem; color: #990000"
          ><b>￥{{ (parseInt(formData.amount) / 100).toFixed(2) }}</b></van-col
        >
      </van-row>
      <van-button type="primary" class="cancle-btn" @click="toConcle"
        >取消</van-button
      >
      <van-button type="primary" class="confirm-btn" @click="toPay"
        >确认</van-button
      >
    </van-dialog>
    <van-dialog
      v-model="showDialog"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h3>提示</h3>
      </template>
      <template #default>
        <p>确认关闭当前订单吗？</p>
        <van-button class="cancle-btn" @click="showDialog = false"
          >取消</van-button
        >
        <van-button type="primary" class="confirm-btn" @click="submit"
          >确认</van-button
        >
      </template>
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "wxPayPage",
  data() {
    return {
      formData: {
        id: "",
        name: "",
        lableName: "",
        transactionId: "",
        changeTime: "",
        amount: "",
        status: "",
      },
      checked: 0,
      appId: "",
      timestamp: "",
      nonce: "",
      packageName: "",
      signType: "",
      signature: "",
      orderid: this.uuid(20, 16),
      id: "",
      name: "",
      show: false,
      showDialog: false,
    };
  },
  methods: {
    /**
     * 确认弹框
     */
    handleConfirm() {
      this.show = true;
    },
    /**
     * 关闭弹框
     */
    toConcle() {
      this.show = false;
    },
    /**
     * 确认支付
     */
    toPay() {
      this.show = false;
      if (parseInt(this.$route.query.type) == 0) {
        // 正常流程
        let postData = {
          id: this.$route.query.id,
          orderid: this.orderid,
        };
        this.$http.get("/api/antigen/wx", postData).then((result) => {
          console.log(result.data);
          this.appId = result.data.appId;
          this.timestamp = result.data.timeStamp;
          this.nonce = result.data.nonceStr;
          this.packageName = result.data.package;
          this.signType = result.data.signType;
          this.signature = result.data.paySign;
          this.isWeChat();
        });
      } else if (parseInt(this.$route.query.type) == 1) {
        // 从门诊缴费记录进来的流程
        let postData = {
          id: this.$route.query.id,
          orderid: this.orderid,
        };
        this.$http.get("/api/antigen/jsapi", postData).then((result) => {
          this.appId = result.data.appId;
          this.timestamp = result.data.timeStamp;
          this.nonce = result.data.nonceStr;
          this.packageName = result.data.package;
          this.signType = result.data.signType;
          this.signature = result.data.paySign;
          this.isWeChat();
        });
      }
    },
    /**
     * 时间格式化
     */
    formatDate() {
      var date = new Date();

      var year = date.getFullYear(),
        month = date.getMonth() + 1, //月份是从0开始的
        day = date.getDate(),
        hour = date.getHours(),
        min = date.getMinutes(),
        sec = date.getSeconds();
      var newTime =
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
      return newTime;
    },
    /**
     * 取消支付
     */
    toCancle() {
      Toast({
        message: "取消成功！",
        duration: 500,
        onClose: () => {
          this.$router.push("/antigenDectectionPayment");
        },
      });
    },
    /**
     * 关闭当前订单
     */
    toClose() {
      this.showDialog = true;
    },
    /**
     * 关闭订单
     */
    submit() {
      let postData = {
        id: this.$route.query.id,
      };
      this.$http
        .put("/api/antigen", postData)
        .then((res) => {
          Toast({
            message: "订单关闭成功",
            duration: 500,
            onClose: () => {
              this.$router.push("/antigenDectectionPayment");
              this.getDataList1();
            },
          });
        })
        .catch((err) => {
          Toast.fail({ message: err.response.data });
          console.log(err);
        });
      this.showDialog = false;
    },
    showData() {
      if (this.$route.query.collectType == 0) {
        this.formData.lableName = "抗原检测";
      } else if (this.$route.query.collectType == 1) {
        this.formData.lableName = "新型冠状病毒核酸检测（标本混采）";
      } else if (this.$route.query.collectType == 2) {
        this.formData.lableName = "新型冠状病毒核酸检测（标本快检）";
      }
      this.formData.amount = this.$route.query.price;
      this.id = this.$route.query.id;
    },
    onBridgeReady() {
      let self = this;
      if (this.id != "") {
        window.WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: self.appId, //公众号名称，由商户传入
            timeStamp: self.timestamp, //时间戳，自1970年以来的秒数
            nonceStr: self.nonce, //随机串
            package: self.packageName,
            signType: self.signType, //微信签名方式：
            paySign: self.signature, //微信签名
          },
          function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // let postData = {
              //   id: self.id
              // }
              // self.$http.get('api/nucleic/confirm',postData)
              //     .then((result) => {
              //       if(result.data){
              Toast({
                message: "充值成功！",
                duration: 500,
                onClose: () => {
                  self.$router.push({
                    path: "/treatmentServices",
                  });
                },
              }); // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。
              // if(!self.$route.query.success){
              //           // window.location.href=`${domain}index.html%23%2FtreatmentServices`
              //         // }else {
              //         //   // 预约挂号支付状态为true
              //         //   sessionStorage.setItem("appointPayState",true);
              //         //   sessionStorage.setItem("covidorderid",self.$route.query.id);
              //         //   window.location.href=`http://wx.hns2y.com/client/index.html#/paySuccess`
              //         // }
              //       }else{
              //         Toast({ message: "充值失败！" })
              //       }
              //     })
            }
            // else {
            //   let postData = {
            //     id: self.id,
            //     message: res.err_msg
            //   }
            //   this.$http.get('api/nucleic/error',postData)
            //       .then(()=>{
            //         console.log("支付失败");
            //       })
            // }
          }
        );
      }
    },
    isWeChat() {
      let self = this;
      if (typeof WeixinJSBridge == "undefined") {
        self.onBridgeReady();
        Toast({ message: "请在微信客户端进行支付操作" });
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            self.onBridgeReady(),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", self.onBridgeReady());
          document.attachEvent("onWeixinJSBridgeReady", self.onBridgeReady());
        }
      } else {
        self.onBridgeReady();
      }
    },
    // 生成uuid的方法
    uuid(len, radix) {
      var chars =
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
          ""
        );
      var uuid = [],
        i;
      radix = radix || chars.length;

      if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
      } else {
        // rfc4122, version 4 form
        var r;

        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
        uuid[14] = "4";

        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
          if (!uuid[i]) {
            r = 0 | (Math.random() * 16);
            uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
          }
        }
      }

      return uuid.join("");
    },
  },
  mounted() {
    this.showData();
    const CurrentJZPerson = JSON.parse(
      sessionStorage.getItem("CurrentJZPerson")
    );
    this.name = CurrentJZPerson.name;
  },
};
</script>

<style scoped>
.body {
  width: 9rem;
  height: auto;
  border-radius: 5px;
  margin: 0px auto;
  padding: 12px 3px;
  font-size: 0.4rem;
  line-height: 0.9rem;
}
.orderItem {
  text-align: left;
}
.orderItem-label {
  display: inline-block;
  width: 2.2rem;
  text-align: right;
}
.appwx_logo {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  margin-right: 10px;
}
.appwx_text {
  font-size: 0.5rem;
  vertical-align: middle;
}
.pay-btn {
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
}

.confirm-tips {
  color: #5ba5cf;
  font-size: 0.4rem;
}
.dialog {
  padding: 0.5rem;
  padding-top: 0;
  line-height: 0.8rem;
  box-sizing: border-box;
}

.dialog div {
  text-align: left;
}
.dialog p {
  text-align: left;
  font-size: 0.4rem;
}

.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-top: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}

.dialog-title {
  margin-bottom: 0.3rem;
}
.cancle-btn {
  width: 40%;
  height: 40px;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}
/deep/ .van-cell {
  background-color: transparent;
  padding: 10px 0;
}
/deep/ .van-cell-group {
  background-color: transparent;
}
/deep/ .van-cell__title {
  text-align: left;
}
/deep/ .van-radio {
  float: right;
  clear: both;
}
/deep/ .van-button {
  padding: 5px 0;
}
</style>
